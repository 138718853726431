import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();


$(document).ready(function(){

//ajax handlers    
var $docroot, $winloc, $publication, $company, $slug, $slugArr, $hash, $prevScrollTop;    
$winloc =  document.location.href;    
$docroot = $("html").attr("data-root");
$publication = "";
$company = $("html").attr("data-company");
$slug = $winloc.replace($docroot, "").replace(/\/+$/, "");    
$slugArr = $slug.split("/");       
    
window.addEventListener('popstate', function(event) {  
   console.log("location: " + document.location + ", state: " + JSON.stringify(event.state)); 
   $slug =  document.location.toString();

   $slug = $slug.replace($docroot, "");
  //alert("popped");
  takeAction($slug);
    
    
});


$(document).on("click", 'a', function(e){

e.preventDefault();
$slug = $(this).attr("href").replace($docroot, "").replace(/\/+$/, "");

takeAction($slug);
    
});
    
    
function takeAction($slug){
    
$slugArr = $slug.split("/");   
     
   //alert($slug.indexOf("#"));
if($slugArr) {     
    
actionlist: {
    
if($slug.indexOf("http") === 0){    
window.open($slug);
break actionlist;
}  
if($slug.indexOf("mailto") === 0){    
window.location.href = $slug;
break actionlist;
}     
if($slug.indexOf("download/") === 0){    
window.location.href = $docroot+$slug;
break actionlist;
}
if($slug.indexOf("downloads/") === 0){    
window.open($docroot+$slug, '_blank');
window.focus();   
break actionlist;
}    
if($slug.indexOf("#") === 0){  
scrollView($slug);
hideArticles();    

break actionlist;
}       
if($slug.length <= 2 ){ 
window.location.href = $docroot+$slug;
break actionlist;
}  
if($("article[data-article-slug='"+$slug+"']").hasClass("active")){ 
scrollArticle("", $prevScrollTop);   
break actionlist;
}     

buildArticle($slug);
showArticles();   
    
if($slug.indexOf("#") > 0){    
scrollArticle($slug);
break actionlist;
}     
}   
}        
}
    
function scrollView(el){
$('body, html').stop().animate({ scrollTop: $(el).position().top}, 400);     
}

function launch(){  
if($slugArr.length > 0 && $slugArr[0]!="" && $slugArr[0].indexOf("#") != 0){
    //alert($slugArr[0]);
    buildArticle($slug);
    showArticles();  
    
}     
    
}
launch();    

function scrollArticle(bookmark, scrollpoint = 0){
    
  var container = $('article.active');   
    
 
if(bookmark.length > 1){       
var target = $(bookmark);     
$prevScrollTop =  container.scrollTop();  
var scrollPos = container.scrollTop() + target.position().top;

container.stop().animate({ scrollTop: scrollPos}, 400);    
    
    
 
} else {
    //alert(scrollpoint);   
var scrollPos = container.scrollTop() + scrollpoint;    
  
  container.stop().animate({ scrollTop: scrollpoint}, 50);    
    
}   

    
}

function fetchArticle(slug){
console.log("getting..."+$docroot+slug);  
    
    
 $.ajax({
          url: $docroot+slug,
          type: 'POST',
          dataType: 'json',
          success: function (data) {
               $('#articles').append(data.html).foundation(); //output the returned html into #some-div
               //console.log(data.AcceptedServices); //view the returned json in the console
              timemachine(slug, data.title, data.keywords, data.description);
          },
          error: function (data ) {
              console.log(data);
              console.log('error');
          }
      });
}

function buildArticle(slug){
  
var uri =  slug.split("#")[0]; 
var bookmark = slug.split("#")[1];    
console.log(slug);   
    
    
console.log($("#articles").has('article[data-article-slug="'+uri+'"]').length);    

if($("#articles").has('article[data-article-slug="'+uri+'"]').length <= 0){
    
console.log(uri);         
    fetchArticle(slug);
    showArticle(slug); 
    
} else {
    showArticle(slug);
    timemachine(slug, "");    
} 
    
    
}

function timemachine(slug, title="", keywords="", description=""){

if(title === ""){      
    title = $("article.active").attr("data-title");   
} 
if(title != "" && title != undefined){   
title = title+" | "+$company+" "+$publication;
slug = $docroot+slug;
}    
if(slug === undefined){
  title = $company+" "+$publication; 
  slug = $docroot;  
} 

    /*
    if ("ga" in window) {
    var tracker = ga.getAll()[0];
    if (tracker) {
        tracker.set('page', slug.replace(location.origin, ""));
        tracker.send('pageview');        
    }
    }
    */

var stateObj = {title: title};
history.pushState(stateObj, title, slug); 
document.title = title;
$('meta[name="description"]').attr("content", description);  
$('meta[name="keywords"]').attr("content", keywords);      
console.log(stateObj); 
}  

$(document).on("click", '.close-article', function(e){     
	e.preventDefault();
     
	hideArticles();		 
}); 

$(document).on("click", '.scroller', function(e){     
	e.preventDefault();
    scrollView("#about"); 

}); 
    
 
    
function hideArticles(){
  $("body").removeClass("show-article");  
  $("article").removeClass("active");     
  timemachine();

}    
  
    
function showArticles(){
    $("body").addClass("show-article");
    $("body").removeClass("hide-header");
    $(".sticky").addClass("is-stuck");
}    
    
    
function showArticle(slug){
var uri =  slug.split("#")[0]; 
var bookmark = slug.split("#")[1];     
    
//hide other articles      
    
if($('article[data-article-slug="'+uri+'"]').hasClass("active")) {
    scrollArticle("#", $prevScrollTop);   
} else {
 $('article[data-article-slug="'+uri+'"]').addClass("active");    
}  
$('article:not([data-article-slug="'+uri+'"])').removeClass("active");     
//$('#primary').foundation('close');  
$(".button").removeClass("active");    
$("#main-menu").removeClass("active");    
   
if (typeof bookmark != 'undefined') {    
var timeout = setTimeout(function(){ scrollArticle("#"+bookmark); }, 600);       
    }
}    
    

$(document).bind('scroll',function(e){
    if($(this).scrollTop > 50){
        $("body").removeClass("hide-header");
        
    } else {
        
       $("body").addClass("hide-header");  
    }
    
    
    $('section:not(#articles)').each(function(){
        if (
           $(this).offset().top < window.pageYOffset + 10
//begins before top
        && $(this).offset().top + $(this).height() > window.pageYOffset + 10
//but ends in visible area
//+ 10 allows you to change hash before it hits the top border
        ) {
            
            console.log($(this).attr('id'));
            timemachine("#"+$(this).attr('id'), $(this).attr('data-chapter'));
        }
    });
});
    
    
    
$(".sitemap-toggle").on('click', function(event){     
    $("body").toggleClass("show-sitemap");     
});    

$("#sitemap a, a.logo").on('click', function(event){     

    $("body").removeClass("show-sitemap"); 
 
});     
    
$("#primary a, a.logo, #sitemap a").on('click', function(event){     
    hideArticles();  

 
});    
    
    

});
